import { QueryClient } from '@tanstack/react-query'

export const CacheKeys = {
  CountPending: 'COUNT_PENDING',
  GetStrips: 'GET_STRIPS',
  GetImages: 'GET_IMAGES',
  GetUserDetails: 'GET_USER_DETAILS',
  Disclaimer: 'GET_DISCLAIMER',
  GetKehila: 'GET_KEHILA',
  GetMahoz: 'GET_MAHOZ',
  GetMahozot: 'GET_MAHOZOT',
  GetKen: 'GET_MAHOZ',
  GetKenim: 'GET_KENIM',
  Qna: 'QNA',
  catagories: 'CATAGORIES',
  Positions: 'POSITIONS',
  GetCycles: 'GET_CYCLES',
  Affiliation: 'AFFILIATION',
  GetShchavot: 'GET_SHCHAVOT',
  GetPeilut: 'GET_PEILUT',
  GetPeiluts: 'GET_PEILUTS',
  PeilutCreationForm: 'PEILUT_CREATION_FORM',
  GetMyActivities: 'GET_MY_ACTIVITIES',
  GetMyFavoritedActivities: 'GET_MY_FAVORITED_ACTIVITIES',
} as const

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000,
    },
  },
})
