import { createStore } from 'zustand/vanilla'

export type ToastMessage = {
  type: 'success' | 'error'
  title: string
  description: string
}

export type MessagesStore = {
  addToast: (message: ToastMessage) => void
  toasts: ToastMessage[]
  clearToasts: () => void
}

export const messagesStore = createStore<MessagesStore>((set, get) => {
  return {
    toasts: [],
    clearToasts: () => set({ toasts: [] }),
    addToast: (message) => {
      set({ toasts: [...get().toasts, message] })
    },
  }
})
